import { Alert, Button, CircularProgress, Dialog, DialogContent } from "@mui/material";
import React, { useState } from "react";
import axiosInstance from "../../axios";


const TryFree = ({ CloseResp }) => {
    const [resp, setResp] = useState(false);
    const organization_id = localStorage.getItem('organization')
    const email = localStorage.getItem('email')
    const UpdateLicense = async () => {
        setResp('progress');
        try {
            const response = await axiosInstance.post(`${process.env.REACT_APP_ENDPOINT}/organization/${organization_id}/updateLicense?transactionRef=Free&email=${email}&amount=0&license_type=3&bill_type=monthly`);
            setResp('updated');
        } catch (error) {
            //console.log('error in updating', error)
            setResp('error');
        }
    };
    const closeDialog = () => {
        if (resp === 'updated') {
            localStorage.setItem('plan', 3);
            setResp(false);
            CloseResp();
            window.location.reload();

        } else { setResp(false); CloseResp();; }
    }
    // create date of today one month plus
    const addOneMonth = () => {
        const currentDate = new Date();
        const nextMonth = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
        // Subtract one day from the new date
        nextMonth.setDate(currentDate.getDate() - 1);
        // Format the date as DD/MM/YYYY
        const day = String(nextMonth.getDate()).padStart(2, '0');
        const month = String(nextMonth.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const year = nextMonth.getFullYear();

        return `${day}/${month}/${year}`;
    }

    return (
        <div>
            <Dialog open={resp} onClose={closeDialog}>
                <DialogContent>
                    {resp === 'progress' ? (<div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
                        <CircularProgress className="text-blue-800" />
                    </div>) : (<div><Alert severity={resp === 'updated' ? 'success' : 'error'}>
                        {resp === 'updated' ? `Congaratulations 🎉, You now have the premium plan for a month your free trial ends by ⌚
            ${addOneMonth()}.` : 'An error occured please try again later.'}</Alert></div>)}
                </DialogContent>
                {resp !== 'progress' && <div className="flex justify-center">
                    <Button onClick={closeDialog}>{resp==='updated'? 'Game ON' : 'Okay'}</Button>
                </div>}
            </Dialog>
            <span onClick={UpdateLicense}>Try free</span></div>
    )
};
export default TryFree;